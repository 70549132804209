import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass/styled-components';

import useAppData from '~/api/appData';

import AppContext from './AppContext';
import Loader from './Loader';
import MediaQueryProvider from './MediaQueryProvider';

function App({ children, context }) {
  const { loading } = useAppData();

  const loadingApp = (
    <Flex
      height="100vh"
      width="100vw"
      justifyContent="center"
      alignItems="center"
    >
      <Loader />
    </Flex>
  );

  return (
    <AppContext.Provider value={{ context }}>
      <MediaQueryProvider>
        {React.Children.only(loading ? loadingApp : children)}
      </MediaQueryProvider>
    </AppContext.Provider>
  );
}

App.propTypes = {
  context: PropTypes.shape({
    // Universal HTTP client
    loggedIn: PropTypes.bool.isRequired,
    fetch: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    query: PropTypes.object,
  }).isRequired,
  children: PropTypes.element.isRequired,
};

export default App;

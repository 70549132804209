import { ApolloClient, HttpLink, InMemoryCache, makeVar } from '@apollo/client';
import { useMemo } from 'react';

import getListFilterQuery from '~/api/query/listFilter';

import config from './config';
import { LIST_FILTER_ID } from './constants';
import resolvers from './resolvers';
import typeDefs from './typeDefs';

let apolloClient;

export const courseSpecialityCountVar = makeVar(0);
export const courseProfessionCountVar = makeVar(0);
export const courseLevelCountVar = makeVar(0);

const mergeElasticItems = (existing, incoming, { args }) => {
  const page = args?.page || 1;
  const perPage = args?.perPage || 25;
  const items = existing?.items ? existing.items.slice(0) : [];
  // From args of searchPagination config
  const offset = (page - 1) * perPage;
  const end = offset + Math.min(perPage, incoming.items.length);

  // Insert the incoming elements in the right places.
  for (let i = offset; i < end; ++i) {
    items[i] = incoming.items[i - offset];
  }

  return {
    ...existing,
    ...incoming,
    items,
  };
};

const createCache = () => {
  const cacheOptions = {
    typePolicies: {
      Query: {
        fields: {
          cachedTaxonomyById(_, { args, toReference }) {
            return toReference({
              __typename: 'Taxonomy',
              document_id: args.id,
            });
          },
          cachedTermById(_, { args, toReference }) {
            return toReference({
              __typename: 'Term',
              document_id: args.id,
            });
          },
          cachedUserById(_, { args, toReference }) {
            return toReference({
              __typename: 'User',
              document_id: args.id,
            });
          },
          cachedUserRoleById(_, { args, toReference }) {
            return toReference({
              __typename: 'UserRole',
              document_id: args.id,
            });
          },
          cachedOrganizationById(_, { args, toReference }) {
            return toReference({
              __typename: 'Organization',
              document_id: args.id,
            });
          },
          taxonomyPagination: {
            keyArgs: false,
            merge: mergeElasticItems,
          },
          termPagination: {
            keyArgs: false,
            merge: mergeElasticItems,
          },
          userPagination: {
            // keyArgs: ['boolFilter', 'sort', 'page', 'perPage'],
            merge: mergeElasticItems,
          },
          userRolePagination: {
            keyArgs: false,
            merge: mergeElasticItems,
          },
        },
      },
      CourseDescription: {
        keyFields: ['document_id'],
        fields: {
          id: {
            read(_, { readField }) {
              return readField('document_id');
            },
          },
        },
      },
      CourseEvaluation: {
        keyFields: ['document_id'],
        fields: {
          id: {
            read(_, { readField }) {
              return readField('document_id');
            },
          },
        },
      },
      CourseSubscription: {
        keyFields: ['document_id'],
        fields: {
          id: {
            read(_, { readField }) {
              return readField('document_id');
            },
          },
        },
      },
      Inspection: {
        keyFields: ['document_id'],
        fields: {
          id: {
            read(_, { readField }) {
              return readField('document_id');
            },
          },
        },
      },
      Organization: {
        keyFields: ['document_id'],
        fields: {
          id: {
            read(_, { readField }) {
              return readField('document_id');
            },
          },
        },
      },
      Page: {
        keyFields: ['document_id'],
        fields: {
          id: {
            read(_, { readField }) {
              return readField('document_id');
            },
          },
        },
      },
      Post: {
        keyFields: ['document_id'],
        fields: {
          id: {
            read(_, { readField }) {
              return readField('document_id');
            },
          },
        },
      },
      Report: {
        keyFields: ['document_id'],
        fields: {
          id: {
            read(_, { readField }) {
              return readField('document_id');
            },
          },
        },
      },
      Taxonomy: {
        keyFields: ['document_id'],
      },
      Term: {
        keyFields: ['document_id'],
      },
      User: {
        keyFields: ['document_id'],
        fields: {
          id: {
            read(_, { readField }) {
              return readField('document_id');
            },
          },
        },
      },
      UserRole: {
        keyFields: ['document_id'],
      },
    },
  };

  const cache = new InMemoryCache(cacheOptions);

  // Initial data

  // Default ROOT QUERY for ListFilter
  // TODO refactor listFilterQuery to reactive variable
  Object.values(LIST_FILTER_ID).forEach(listId => {
    cache.writeQuery({
      query: getListFilterQuery,
      data: {
        getListFilter: {
          __typename: 'ListFilter',
          id: listId,
          filter: {},
          sort: [],
          page: 1,
          perPage: 25,
        },
      },
    });
  });

  return cache;
};

function createApolloClient() {
  const fetchLink = new HttpLink({
    uri: config.apiUrl,
    credentials: 'same-origin',
    fetch,
  });

  return new ApolloClient({
    ssrMode: false,
    // Compose all links to chain
    link: fetchLink,
    cache: createCache(),
    typeDefs,
    resolvers,
  });
}

export function initializeApollo(initialState = null) {
  const initializedClient = apolloClient ?? createApolloClient();

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = initializedClient;
  }

  return initializedClient;
}

export function useApollo(initialState) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}

import colors from './colors';

const forms = {
  input: {
    borderRadius: 3,
    borderColor: colors.border,
    bg: '#fff',
    fontSize: ['16px', 2],
    padding: [3, null, 4],
    ':focus': {
      bg: 'white',
      outline: 'none',
      borderWidth: 1,
      borderColor: 'highlight',
    },
  },
  select: {
    borderRadius: 3,
    borderColor: colors.border,
    bg: '#fff',
    padding: [3, null, 4],
  },
  label: {
    fontSize: 3,
  },
};

export default forms;

let apiUrl = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENV === 'production') {
  apiUrl = 'https://hjartat.lipus.se/api/v1/graphql';
}

if (process.env.REACT_APP_ENV === 'staging') {
  apiUrl = 'https://hjartat.test.onparken.com/api/v1/graphql';
}

const config = {
  apiUrl,
};

export default config;

import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo } from 'react';
import useMedia from 'use-media';

import breakpoints from '~/core/theme/breakpoints';

export const MediaQueryContext = createContext(null);

const mediaQueries = {
  sm: `(min-width: ${breakpoints.sm})`,
  md: `(min-width: ${breakpoints.md})`,
  lg: `(min-width: ${breakpoints.lg})`,
  xl: `(min-width: ${breakpoints.xl})`,
  xxl: `(min-width: ${breakpoints.xxl})`,
};

function MediaQueryProvider({ children }) {
  const smallMinView = useMedia(mediaQueries.sm);
  const mediumMinView = useMedia(mediaQueries.md);
  const largeMinView = useMedia(mediaQueries.lg);
  const xlMinView = useMedia(mediaQueries.xl);
  const xxlMinView = useMedia(mediaQueries.xxl);

  const value = useMemo(
    () => ({
      smallMinView,
      mediumMinView,
      largeMinView,
      xlMinView,
      xxlMinView,
    }),
    [smallMinView, mediumMinView, largeMinView, xlMinView, xxlMinView],
  );

  return (
    <MediaQueryContext.Provider value={value}>
      {children}
    </MediaQueryContext.Provider>
  );
}

MediaQueryProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export function useMediaQueryContext() {
  return useContext(MediaQueryContext);
}

export default MediaQueryProvider;

import https from 'https';
import fetch from 'node-fetch';

export const auth_path = '/wp-json/jwt-auth/v1/token';
export const validate_path = '/wp-json/jwt-auth/v1/token/validate';
export const acl_path = '/wp-json/lipus-digitala-hjartat/v1/auth-validate';
export const cookie_name = 'login_hjartat';

const isProd = process.env.NODE_ENV === 'production';

const agent = new https.Agent({
  rejectUnauthorized: isProd,
});

let sessions = {};

const universalAtob = b64Encoded => {
  try {
    return window.atob(b64Encoded);
  } catch (err) {
    return Buffer.from(b64Encoded, 'base64').toString();
  }
};

export const getUrlBase = origin => {
  return `https://${origin}.lipus${isProd ? '.se' : '.test'}`;
};

export const readToken = token => {
  try {
    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const data = JSON.parse(universalAtob(base64));
      return data;
    }
  } catch (error) {
    console.error(error);
  }
};

export async function sessionMiddleware(req, res, next) {
  req.getSession = async () => {
    const token = req.cookies[cookie_name];

    if (!token) {
      return false;
    }

    const tokenData = readToken(token);

    // This is origin to validate against
    const {
      iss,
      data: {
        user: { id: userId },
      },
    } = tokenData;
    const unique = `${iss.replace(/[^a-zA-Z]/g, '')}.${userId}`;

    if (sessions.hasOwnProperty(unique)) {
      return true;
    }

    const hosts = [getUrlBase('spurnet'), getUrlBase('kursverktyg')];

    if (hosts.includes(iss) === false) {
      return false;
    }

    const params = {
      agent,
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    // Validate token
    let response = await fetch(`${iss}${validate_path}`, params);
    response = await response.json();

    const {
      code,
      data: { status },
    } = response;

    const valid = status === 200 && code === 'jwt_auth_valid_token';

    if (valid) {
      sessions[unique] = tokenData;
    }

    return valid;
  };

  next();
}

export function checkSession() {
  return async (req, res, next) => {
    const loggedIn = await req.getSession();

    if (!loggedIn) {
      res.status(403);
      res.json({ errors: [{ msg: 'Forbidden' }] });
    }

    return next();
  };
}

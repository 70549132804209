import {
  SITE_BASE_TITLE,
  SITE_DESCRIPTION,
  SITE_TITLE_SEPARATOR,
  urlBaseCourse,
  urlBaseCourseEvaluations,
  urlBaseCourseSubscriptions,
  urlBaseCourseUser,
  urlBaseSpur,
  urlBaseSpurInspection,
  urlBaseSpurReportAt,
  urlBaseSpurReportSt,
  urlBaseSpurUser,
} from '~/core/constants';

const baseTitle = `${SITE_TITLE_SEPARATOR} ${SITE_BASE_TITLE}`;

const routes = {
  // The top-level (parent) route
  path: '',

  // Child routes - Keep in mind, routes are evaluated in order
  children: [
    // Login
    {
      path: '/logga-in',
      load: () => import(/* webpackChunkName: 'logga-in' */ './logga-in'),
    },
    // Redirect if user is not logged in
    {
      path: '/(.*)',
      action({ loggedIn, pathname }) {
        if (!loggedIn) {
          return {
            status: 303,
            redirect: `/logga-in?redirect=${encodeURIComponent(pathname)}`,
          };
        }
        // All OK continue
        return undefined;
      },
    },
    // Home route
    {
      path: '',
      load: () =>
        import(
          /* webpackChunkName: 'home' */
          './home'
        ),
    },
    // About
    {
      path: '/om',
      children: [
        // Digitala hjartat
        {
          path: '/digitala-hjartat',
          load: () =>
            import(
              /* webpackChunkName: 'about-digitala-hjartat' */
              './about/digitala-hjartat'
            ),
        },
      ],
    },
    // SPUR-granskning routes
    {
      path: urlBaseSpurInspection,
      load: () =>
        import(
          /* webpackChunkName: 'spur-inspection' */
          './spur/inspection'
        ),
    },
    {
      path: urlBaseSpurUser,
      load: () =>
        import(
          /* webpackChunkName: 'spur-users' */
          './spur/users'
        ),
    },
    {
      path: `${urlBaseSpurUser}/:id`,
      load: () =>
        import(
          /* webpackChunkName: 'spur-users-single' */
          './spur/users/single'
        ),
    },
    {
      path: `${urlBaseSpurReportAt}/overgripande/bedomningar-a-h`,
      load: () =>
        import(
          /* webpackChunkName: 'spur-report-at-overgripande-result' */
          './spur/report-at/overgripande/result'
        ),
    },
    {
      path: `${urlBaseSpurReportAt}/overgripande/checklistan-totalresultat`,
      load: () =>
        import(
          /* webpackChunkName: 'spur-report-at-overgripande-checklist' */
          './spur/report-at/overgripande/checklist'
        ),
    },
    {
      path: `${urlBaseSpurReportAt}/bedomningar-a-h`,
      load: () =>
        import(
          /* webpackChunkName: 'spur-report-at-result' */
          './spur/report-at/result'
        ),
    },
    {
      path: `${urlBaseSpurReportAt}/checklistan-totalresultat`,
      load: () =>
        import(
          /* webpackChunkName: 'spur-report-at-checklist' */
          './spur/report-at/checklist'
        ),
    },
    {
      path: `${urlBaseSpurReportSt}/230401/bedomningar-a-h`,
      load: () =>
        import(
          /* webpackChunkName: 'spur-report-st-result' */
          './spur/report-st/result'
        ),
    },
    {
      path: `${urlBaseSpurReportSt}/230401/checklistan-totalresultat`,
      load: () =>
        import(
          /* webpackChunkName: 'spur-report-st-checklist' */
          './spur/report-st/checklist'
        ),
    },
    {
      path: `${urlBaseSpurReportSt}/bedomningar-a-h`,
      load: () =>
        import(
          /* webpackChunkName: 'spur-report-st-2021-result' */
          './spur/report-st/2021/result'
        ),
    },
    {
      path: `${urlBaseSpurReportSt}/checklistan-totalresultat`,
      load: () =>
        import(
          /* webpackChunkName: 'spur-report-st-2021-checklist' */
          './spur/report-st/2021/checklist'
        ),
    },
    {
      path: `${urlBaseSpur}/:id`,
      load: () =>
        import(
          /* webpackChunkName: 'spur-inspection-single' */
          './spur/inspection/single'
        ),
    },
    {
      path: urlBaseSpur,
      load: () =>
        import(
          /* webpackChunkName: 'spur' */
          './spur'
        ),
    },
    // Kurscertifiering routes
    {
      path: `${urlBaseCourseUser}/:id`,
      load: () =>
        import(
          /* webpackChunkName: 'course-users-single' */
          './course/users/single'
        ),
    },
    {
      path: urlBaseCourseUser,
      load: () =>
        import(
          /* webpackChunkName: 'course-users' */
          './course/users'
        ),
    },
    {
      path: `${urlBaseCourseEvaluations}/jamfor-kursutvarderingar`,
      load: () =>
        import(
          /* webpackChunkName: 'course-evaluations-compare' */
          './course/evaluations/compare'
        ),
    },
    {
      path: `${urlBaseCourseEvaluations}/:id`,
      load: () =>
        import(
          /* webpackChunkName: 'course-evaluations-single' */
          './course/evaluations/single'
        ),
    },
    {
      path: urlBaseCourseEvaluations,
      load: () =>
        import(
          /* webpackChunkName: 'course-evaluations' */
          './course/evaluations'
        ),
    },
    {
      path: urlBaseCourseSubscriptions,
      load: () =>
        import(
          /* webpackChunkName: 'course-subscriptions' */
          './course/subscriptions'
        ),
    },
    {
      path: `${urlBaseCourse}/:id`,
      load: () =>
        import(
          /* webpackChunkName: 'course-single' */
          './course/single'
        ),
    },
    {
      path: urlBaseCourse,
      load: () =>
        import(
          /* webpackChunkName: 'course' */
          './course'
        ),
    },
    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      load: () =>
        import(
          /* webpackChunkName: 'not-found' */
          './not-found'
        ),
    },
  ],
  async action({ next }) {
    // Execute each child route until one of them return the result
    const route = await next();

    // Provide default values for title, description etc.
    route.title = `${route.title || 'Untitled Page'} ${baseTitle}`;
    route.description = route.description || SITE_DESCRIPTION;

    return route;
  },
};

export default routes;

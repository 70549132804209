import { borders, radii, shadows } from './borders';
import breakpoints from './breakpoints';
import buttons from './buttons';
import colors from './colors';
import forms from './forms';
import sizes from './sizes';
import space, { remScale } from './space';
import { fonts, fontSizes, fontWeights, lineHeights } from './typography';

const theme = {
  borders,
  breakpoints,
  buttons,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  forms,
  radii,
  rems: remScale,
  shadows,
  sizes,
  space,
  variants: {
    card: {
      p: 5,
      height: '100%',
      bg: 'white',
      boxShadow: 'card',
      borderRadius: [3, null, 4],
    },
  },
};

export default theme;

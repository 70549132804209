import { gql } from '@apollo/client';

export const appTaxonomyQuery = gql`
  query appTaxonomy($perPage: Int!, $page: Int!) {
    elastic70 {
      count
    }
    taxonomy: taxonomyPagination(perPage: $perPage, page: $page) {
      count
      pageInfo {
        __typename
        currentPage
        hasNextPage
        itemCount
      }
      items {
        __typename
        id: _id
        _source {
          __typename
          document_id
          name
          name_singular
          taxonomy
        }
      }
    }
  }
`;

export const appTermQuery = gql`
  query appTerm($perPage: Int!, $page: Int!) {
    elastic70 {
      count
    }
    term: termPagination(perPage: $perPage, page: $page) {
      count
      pageInfo {
        __typename
        currentPage
        hasNextPage
        itemCount
      }
      items {
        __typename
        id: _id
        _source {
          __typename
          document_id
          name
          parent
          taxonomy
          origin
        }
      }
    }
  }
`;

export const appUserQuery = gql`
  query appUser($perPage: Int!, $page: Int!) {
    elastic70 {
      count
    }
    user: userPagination(perPage: $perPage, page: $page) {
      count
      pageInfo {
        __typename
        currentPage
        hasNextPage
        itemCount
      }
      items {
        __typename
        id: _id
        _source {
          __typename
          id @client
          user_id
          document_id
          created
          first_name
          full_name
          last_name
          last_name_sort
          roles
          email
          phone
          company_name
          association_name
          avatar_url
          origin
          course_count
          inspection_count
          is_active
        }
      }
    }
  }
`;

export const appUserRoleQuery = gql`
  query appUserRole($perPage: Int!, $page: Int!) {
    elastic70 {
      count
    }
    userRole: userRolePagination(perPage: $perPage, page: $page) {
      count
      pageInfo {
        __typename
        currentPage
        hasNextPage
        itemCount
      }
      items {
        __typename
        id: _id
        _source {
          __typename
          document_id
          role_key
          role_name
        }
      }
    }
  }
`;

export const appOrganizationQuery = gql`
  query appOrganization($perPage: Int!, $page: Int!) {
    elastic70 {
      count
    }
    organization: organizationPagination(perPage: $perPage, page: $page) {
      count
      pageInfo {
        __typename
        currentPage
        hasNextPage
        itemCount
      }
      items {
        __typename
        id: _id
        _source {
          __typename
          document_id
          origin
          org_name
          org_display_name
          org_number
          org_address
          org_zip
          org_city
          org_country
          org_email_billing
          org_gln_number
          org_billing_address
        }
      }
    }
  }
`;

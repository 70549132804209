const common = {
  px: 4,
  py: 3,
  borderRadius: 2,
  fontSize: 2,
  fontWeight: 'semiBold',
  lineHeight: 'button',
  transition: `background-color 200ms ease-in-out, box-shadow 200ms ease, color 200ms ease`,
  ':focus': {
    outline: 'none',
  },
};

const buttons = {
  primary: {
    ...common,
    bg: 'background',
    color: 'base',
    fill: 'base',
    boxShadow: 'inset 0 0 0 1px',
    ':hover': {
      bg: 'highlight',
      color: 'secondary',
      boxShadow: 'inset 0 0 0 1px',
    },
    ':focus': {
      boxShadow: 'none',
    },
  },
  secondary: {
    ...common,
    bg: 'secondary',
    color: 'white',
    fill: 'white',
    ':hover': {
      bg: 'highlight',
      color: 'secondary',
      boxShadow: 'inset 0 0 0 1px',
    },
  },
  disabled: {
    ...common,
    bg: 'border',
    color: 'textMetaLight',
    fill: 'textMetaLight',
    boxShadow: 'none',
    ':hover': {
      bg: 'border',
      color: 'textMetaLight',
      fill: 'textMetaLight',
      cursor: 'not-allowed',
    },
    ':focus': {
      boxShadow: 'none',
    },
  },
  none: {
    border: 0,
    p: 0,
    bg: 'transparent',
    color: 'text',
    fill: 'text',
    ':focus': {
      boxShadow: 'none',
      outline: 'none',
    },
  },
};

export default buttons;

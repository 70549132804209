import { gql } from '@apollo/client';

import listFilterFragment from '~/api/fragment/listFilter';

const getListFilterQuery = gql`
  ${listFilterFragment}
  query getListFilter($id: String!) {
    getListFilter(id: $id) @client {
      ...ListFilter
    }
  }
`;

export default getListFilterQuery;

export const urlBaseCourse = '/kurscertifiering';
export const urlBaseCourseEvaluations = `${urlBaseCourse}/kursutvarderingar`;
export const urlBaseCourseSubscriptions = `${urlBaseCourse}/kursbevakningar`;
export const urlBaseCourseUser = `${urlBaseCourse}/kursproducenter`;

export const urlBaseSpur = '/spur-granskning';
export const urlBaseSpurInspection = `${urlBaseSpur}/granskningar`;
export const urlBaseSpurReportSt = `${urlBaseSpur}/spur-rapporter-st`;
export const urlBaseSpurReportAt = `${urlBaseSpur}/spur-rapporter-at`;
export const urlBaseSpurUser = `${urlBaseSpur}/samordnare-och-inspektorer`;

export const internalReferrers = [
  'localhost',
  'hjartat.test.onparken.com',
  'hjartat.lipus.se',
];

export const SITE_BASE_TITLE = 'Digitala hjärtat';
export const SITE_TITLE_SEPARATOR = '–';
export const SITE_DESCRIPTION = '';

export const ORIGIN = {
  spur: 'spurnet',
  course: 'kursverktyg',
};

export const SPUR_STATUS = {
  registered: 'lipus_70d1dbf8',
  planning: 'lipus_35416689',
  ready: 'lipus_e9081b6a',
  done: 'lipus_d941507f',
  published: 'lipus_5ffc0c4d',
};

export const COURSE_STATUS = {
  created: 'lipus_b03d4334',
  started: 'lipus_a550fd4e',
  needsCompletion: 'lipus_8dbd0564',
  externalReview: 'lipus_f6ef1743',
  externalReviewDone: 'lipus_70e8cb2c',
  accepted: 'lipus_f43c68ef',
  rejected: 'lipus_2129ca9f',
  canceled: 'lipus_5b351a45',
  deleted: 'lipus_3f343479',
};

export const COURSE_TYPE = {
  single: 'lipus_0e202c0b', // Ett kurstillfälle
  recurring: 'lipus_16b97de2', // Flera kurstillfällen
  congress: 'lipus_8acc88c8', // Kongress eller större kurser
  elearning: 'lipus_63aaa20c', // E-learning
};

export const WP_ROLES = {
  administrator: 'lipus_administrator',
  author: 'lipus_author',
  contributor: 'lipus_contributor',
  editor: 'lipus_editor',
  subscriber: 'lipus_subscriber',
};

export const USER_ROLES = {
  coordinator: 'lipus_spurcoordinator',
  inspector: 'lipus_spurinspector',
  lipus: 'lipus_lipus',
  courseCreator: 'lipus_course_creator',
  courseInspector: 'lipus_course_inspector',
  courseInspectorExternal: 'lipus_course_inspector_external',
};

export const EXTRA_ROLES = {
  seomanager: 'lipus_wpseo_manager',
  seoeditor: 'lipus_wpseo_editor',
};

export const USER_ROLES_SPUR_EXCLUDE = [
  ...Object.keys(WP_ROLES).map(r => WP_ROLES[r]),
  ...Object.keys(EXTRA_ROLES).map(r => EXTRA_ROLES[r]),
  USER_ROLES.lipus,
  USER_ROLES.courseCreator,
  USER_ROLES.courseInspector,
  USER_ROLES.courseInspectorExternal,
];

export const PORTAL_DOM_ID = {
  tableFilter: 'portal-table-filter',
  tablePagination: 'portal-table-pagination',
};

export const STATUS_COLORS = {
  // Registrerad
  [SPUR_STATUS.registered]: 'statusRed',
  // Under planering
  [SPUR_STATUS.planning]: 'statusYellow',
  // Klar för granskning
  [SPUR_STATUS.ready]: 'statusGreen',
  // Genomförd
  [SPUR_STATUS.done]: 'statusGray',
  // Publicerad
  [SPUR_STATUS.published]: 'statusBlue',
  // Extern granskning pågår
  [COURSE_STATUS.externalReview]: 'statusOrange',
  // Extern granskning klar
  [COURSE_STATUS.externalReviewDone]: 'statusOrange',
  // Certifiering avslagen
  [COURSE_STATUS.rejected]: 'statusRed',
  // Påbörjad
  [COURSE_STATUS.created]: 'statusBlue',
  // Certifiering pågår
  [COURSE_STATUS.started]: 'statusOrange',
  // Borttagen kurs
  [COURSE_STATUS.deleted]: 'statusGray',
  // Kräver komplettering
  [COURSE_STATUS.needsCompletion]: 'statusRed',
  // Certifierad av Lipus
  [COURSE_STATUS.accepted]: 'statusGreen',
  // Kurs inställd
  [COURSE_STATUS.canceled]: 'statusRed',
};

export const ELASTIC_PROPERTY_MAP = {
  userRole: {
    spurcoordinator: 'coordinator',
    spurinspector: 'inspector',
    course_creator: 'course_creator',
    course_inspector: 'course_inspector',
    course_inspector_external: 'course_inspector_external',
  },
};

export const LIST_FILTER_ID = {
  courseDescription: 'course_description',
  courseEvaluation: 'course_evaluation',
  courseEvaluationCompare: 'course_evaluation_compare',
  courseSubscription: 'course_subscription',
  courseUser: 'course_users',
  inspectionSt: 'inspection_st',
  inspectionAt: 'inspection_at',
  inspectionUser: 'inspection_users',
  report: 'report',
  reportChecklist: 'report_checklist',
  spurUser: 'spur_users',
};

export const DEFAULT_COURSE_EVALUATION_COMPARE_FILTER = {
  must: [
    {
      term: {
        send_to_lipus: {
          value: true,
        },
      },
    },
  ],
};

export const DEFAULT_USER_FILTER_COURSE = {
  must: [
    {
      term: {
        origin: {
          value: ORIGIN.course,
        },
      },
    },
    {
      term: {
        roles: {
          value: USER_ROLES.courseCreator,
        },
      },
    },
    {
      term: {
        is_active: {
          value: true,
        },
      },
    },
  ],
};

export const DEFAULT_USER_FILTER_SPUR = {
  must: [
    {
      term: {
        origin: {
          value: ORIGIN.spur,
        },
      },
    },
    {
      term: {
        is_active: {
          value: true,
        },
      },
    },
  ],
  should: [
    {
      term: {
        roles: {
          value: USER_ROLES.coordinator,
        },
      },
    },
    {
      term: {
        roles: {
          value: USER_ROLES.inspector,
        },
      },
    },
  ],
  minimum_should_match: '1',
};

export const CHECKLIST_GROUP = [
  {
    label: 'Föreskrift',
    description: `Verksamheten har de förutsättningar som behövs för att genomföra specialiseringstjänstgöring av hög och jämn kvalitet.`,
  },
  {
    label: 'Allmänna råd',
    description: '',
  },
  {
    label: 'Kvalitetsindikator',
    description: '',
  },
];

export const COURSE_EVALUATION_INDEX_LABELS = [
  {
    label: '1. Hur svarade kursen mot kursbeskrivningen?',
  },
  {
    label: '2. Uppfylldes de angivna kursmålen avseende teoretiska kunskaper?',
  },
  {
    label: `3. Uppfylldes de angivna kursmålen avseende praktiska färdigheter? Om praktisk övning saknades i kursen välj alternativ ”Ej tillämpligt”`,
  },
  {
    label: `4. Var kursens pedagogiska arbetsformer anpassad till kursens mål och innehåll?`,
  },
  {
    label: `5. Kommer du att för egen del kunna tillämpa det du lärt dig i din kliniska vardag?`,
  },
  {
    label: `6. Har kursen givit dig stöd (tex material och metoder) att förmedla nyvunna teoretiska kunskaper vidare till kollegor och medarbetare i din verksamhet?`,
  },
  {
    label: '7. Hur bedömer du kursen som helhet?',
  },
  {
    label: `8. Skulle du kunna rekommendera kursen till en kollega i en situation liknande din?`,
  },
  {
    label: `9. Var det något du saknade i kursen eller något du tycker kan utgå?`,
  },
  {
    label: `10. Vilka var kursens tre främsta styrkor respektive svagheter?`,
  },
  {
    label: `11. Har du några förslag till hur kursen skulle kunna förbättras?`,
  },
  {
    label: `12. Övriga kommentarer`,
  },
];

const CHECKLIST_AT_OVERGRIPANDE = [
  {
    key: 'a',
    label: 'A Handledning',
    checks: [
      {
        key: 'a_1',
        label: `Varje AT-läkare har en namngiven huvudhandledare som följer AT-läkaren under hela utbildningen.`,
        group: 0,
      },
      {
        key: 'a_2',
        label: `Varje AT-läkare har återkommande samtal (i grupp eller enskilt) med huvudhandledaren under hela utbildningen.`,
        group: 0,
      },
      {
        key: 'a_3',
        label: `Samtalen med huvudhandledaren är inte i första hand kunskapsinriktade utan berör t.ex. AT-läkarens professionella utveckling och olika aspekter av läkarrollen.`,
        group: 0,
      },
    ],
  },
  {
    key: 'b',
    label: 'B Teoretisk utbildning',
    checks: [
      {
        key: 'b_1',
        label: `Samtliga AT-läkare bereds möjlighet till inblick i verksamhetsplanering, administration och ledarskap t.ex. via ledarskapskurs eller motsvarande.`,
        group: 0,
      },
      {
        key: 'b_2',
        label: `AT-läkarna ges möjlighet att delta i AT-stämman eller motsvarande åtminstone en gång under utbildningen med ersättning från huvudmannen.`,
        group: 1,
      },
      {
        key: 'b_3',
        label: `AT-läkarna genomgår ett introduktionsprogram i huvudmannens regi före tjänstgöringen start.`,
        group: 1,
      },
      {
        key: 'b_4',
        label: `Det finns en studiepott, som utöver deltagande i AT-stämman eller motsvarande, täcker andra externa möten eller kurser.`,
        group: 1,
      },
    ],
  },
  {
    key: 'c',
    label: 'C Kvalitetssäkring av utbildningen',
    checks: [
      {
        key: 'c_1',
        label: `Det finns skriftliga riktlinjer från vårdgivaren om att AT ska genomföras som säkerställer en hög och jämn kvalitet i utbildningen.`,
        group: 0,
      },
      {
        key: 'c_2',
        label: `Målbeskrivningen ligger till grund för hur AT är planerad.`,
        group: 0,
      },
      {
        key: 'c_3',
        label: `AT-studierektorn och andra centralt AT-ansvariga har tydliga uppdrag med skriftlig uppdragsbeskrivning och tid avsatt för arbetet.`,
        group: 1,
      },
      {
        key: 'c_4',
        label: `Den övergripande ledningen är medveten om sjukhusets (sjukvårdsområdets) utbildningsuppdrag.`,
        group: 1,
      },
      {
        key: 'c_5',
        label: `AT-studierektor träffar regelbundet enheternas AT-ansvariga och handledare i s.k. AT-kollegier i avsikt att fånga upp AT-läkare som är i behov av stödjande insatser.`,
        group: 1,
      },
      {
        key: 'c_6',
        label: `Det finns ett system för utvärdering och uppföljning av utbildningens kvalitet centralt på sjukhuset (sjukvårdsområdet).`,
        group: 1,
      },
      {
        key: 'c_7',
        label: `AT-studierektor kvalitetssäkrar de olika tjänstgöringsavsnitten genom avslutningssamtal och/eller enkäter.`,
        group: 1,
      },
      {
        key: 'c_8',
        label: `Återkoppling avseende kvaliteten sker fortlöpande till verksamhetscheferna och/eller delegerade utbildningsansvariga.`,
        group: 1,
      },
      {
        key: 'c_9',
        label: `Skriftlig central åtgärdsplan finns för AT-läkare som är i behov av särskilt stöd.`,
        group: 1,
      },
      {
        key: 'c_10',
        label: `Ledningen är drivande i kvalitetssäkringsarbetet avseende AT och efterfrågar AT-studierektorns synpunkter.`,
        group: 1,
      },
      {
        key: 'c_11',
        label: `Det finns ett sammanhållet chefskap för AT-läkarna som löper under hela AT och chefskapet för AT är skilt från studierektorsfunktionen.`,
        group: 1,
      },
    ],
  },
];

const CHECKLIST_AT = [
  {
    key: 'a',
    label: 'A Verksamheten',
    checks: [
      {
        key: 'a_1',
        label: `Det finns skriftliga riktlinjer från vårdgivaren om att AT ska genomföras som säkerställer en hög och jämn kvalitet i utbildningen.`,
        group: 0,
      },
      {
        key: 'a_2',
        label: `Målbeskrivningen ligger till grund för hur AT är planerad.`,
        group: 0,
      },
      {
        key: 'a_3',
        label: `Det finns skriftliga riktlinjer från vårdgivaren om hur AT ska utvärderas som säkerställer en hög och jämn kvalitet i utbildningen.`,
        group: 1,
      },
      {
        key: 'a_4',
        label: `Verksamheten är tillräckligt allsidig (patientsammansättning, sjukdomspanorama mm) för att målbeskrivningens krav ska kunna uppfyllas.`,
        group: 1,
      },
      {
        key: 'a_5',
        label: `Det finns rimlig balans mellan akut och planerad verksamhet.`,
        group: 1,
      },
      {
        key: 'a_6',
        label: `Den individuella tjänstgöringsplanen för AT är så förankrad att den endast undantagsvis behöver frångås.`,
        group: 1,
      },
    ],
  },
  {
    key: 'b',
    label: 'B Medarbetarstab och interna kompetenser',
    checks: [
      {
        key: 'b_1',
        label: `Samtliga AT-handledare har genomgått handledarutbildning.`,
        group: 0,
      },
      {
        key: 'b_2',
        label: `Det finns tillgång till AT-ansvarig läkare på enheten och som har gått handledarutbildning.`,
        group: 0,
      },
      {
        key: 'b_3',
        label: `Det finns en skriftlig uppdragsbeskrivning för AT-ansvarig.`,
        group: 0,
      },
      {
        key: 'b_4',
        label: `Det finns avsatt tid för AT-ansvarig.`,
        group: 0,
      },
      {
        key: 'b_5',
        label: `Det finns tillräckligt många läkare och andra medarbetare med relevant kompetens för att alla AT-läkare ska få adekvat handledning i det kliniska arbetet (instruktionshandledning).`,
        group: 0,
      },
      {
        key: 'b_6',
        label: `Det finns extra läkarresurs avsatt på enheten för AT-läkarnas kompetensutveckling.`,
        group: 0,
      },
    ],
  },
  {
    key: 'c',
    label: 'C Lokaler och utrustning',
    checks: [
      {
        key: 'c_1',
        label: `Utrustningen för diagnostik, utredning och behandling är adekvat.`,
        group: 0,
      },
      {
        key: 'c_2',
        label: `AT-läkarna har tillgång till en funktionell arbetsplats med dator, som gör det möjligt att arbeta ostört.`,
        group: 0,
      },
      {
        key: 'c_3',
        label: `Det finns tillgång till för specialiteten adekvat bibliotekstjänst och eller motsvarande kunskapskälla.`,
        group: 0,
      },
      {
        key: 'c_4',
        label: `Det finns tillgång till jourrum av accepterad standard.`,
        group: 0,
      },
      {
        key: 'c_5',
        label: `Ett gemensamt välfungerande samlingsrum för AT-läkare finns på sjukhuset.`,
        group: 0,
      },
    ],
  },
  {
    key: 'd',
    label: 'D Tjänstgöringens uppläggning',
    checks: [
      {
        key: 'd_1',
        label: `AT är upplagd utifrån målbeskrivningen.`,
        group: 0,
      },
      {
        key: 'd_2',
        label: `Alla AT-läkare har fått introduktion till tjänstgöringen på enheten.`,
        group: 1,
      },
      {
        key: 'd_3',
        label: `Omfattningen av jourtjänstgöringen står i rimlig proportion till övrig tjänst och har ett klart utbildningsvärde.`,
        group: 1,
      },
      {
        key: 'd_4',
        label: `AT-läkaren har möjlighet till egen mottagning med tillgång till handledare.`,
        group: 1,
      },
      {
        key: 'd_5',
        label: `Det finns möjlighet till att auskultera vid specialistmottagning.`,
        group: 1,
      },
      {
        key: 'd_6',
        label: `Ansvariga chefer på enheten är medvetna om sitt utbildningsansvar.`,
        group: 1,
      },
      {
        key: 'd_7',
        label: `Schemaläggningen anpassas till utbildningsplanen på enheten.`,
        group: 1,
      },
      {
        key: 'd_8',
        label: `Verksamheten har en handlingsplan för AT-läkare som inte uppnår målen.`,
        group: 1,
      },
      {
        key: 'd_9',
        label: `Antalet läkare är tillräckligt för att även ge utrymme för teoretisk utbildning, handledning och utvecklingssamtal under arbetstid.`,
        group: 1,
      },
      {
        key: 'd_10',
        label: `Alla AT-läkare har lika rättigheter, skyldigheter och möjligheter att uppnå målen i målbeskrivningen oavsett kön, könsöverskridande identitet eller uttryck, etnisk tillhörighet, religion, eller annan trosuppfattning, funktionsnedsättning, sexuell läggning eller ålder (Lag om diskriminering 2014:958)`,
        group: 1,
      },
    ],
  },
  {
    key: 'e',
    label: 'E Handledning och uppföljning',
    checks: [
      {
        key: 'e_1',
        label: `Varje AT-läkare har en personlig handledare.`,
        group: 0,
      },
      {
        key: 'e_2',
        label: `Handledningen är överenskommen i förväg, genomförs med regelbundenhet och kontinuitet och på därför avsatt tid.`,
        group: 0,
      },
      {
        key: 'e_3',
        label: `AT-läkaren ges möjlighet att tillsammans med den personliga handledaren och huvudhandledaren regelbundet utvärdera sin tjänstgöring.`,
        group: 0,
      },
      {
        key: 'e_4',
        label: `Det görs en fortlöpande bedömning av AT-läkarens kompetensutveckling på enheten utifrån målbeskrivningen och utbildningsprogrammet.`,
        group: 0,
      },
      {
        key: 'e_5',
        label: `Enheten har ett system för att kontinuerligt utvärdera kvaliteten på utbildningen t.ex. genom enkäter eller dokumenterade ”avslutningssamtal”.`,
        group: 0,
      },
      {
        key: 'e_6',
        label: `Det finns skriftliga instruktioner för handledning och som används som stöd i handledningen.`,
        group: 0,
      },
      {
        key: 'e_7',
        label: `Handledarsamtalen dokumenteras.`,
        group: 0,
      },
      {
        key: 'e_8',
        label: `Den fortlöpande bedömningen görs med på förhand överenskomna och kända metoder som medsittning och/eller videoinspelade patientbesök.`,
        group: 0,
      },
      {
        key: 'e_9',
        label: `Medsittning görs minst en gång under varje tjänstgöringsperiod (”block”).`,
        group: 0,
      },
      {
        key: 'e_10',
        label: `Handledarna diskuterar på enheten AT-läkarnas professionella utveckling på återkommande handledarträffar (”kollegier”).`,
        group: 0,
      },
      {
        key: 'e_11',
        label: `AT-ansvarig / verksamhetschef på enheten har ett avslutande samtal med AT-läkaren innan denne(a) lämnar enheten. Beslut om godkännande tas i i samråd med AT-ansvarig.`,
        group: 0,
      },
    ],
  },
  {
    key: 'f',
    label: 'F Teoretisk utbildning',
    checks: [
      {
        key: 'f_1',
        label: `AT-läkaren deltar i verksamhetens internutbildning.`,
        group: 0,
      },
      {
        key: 'f_2',
        label: `AT-läkaren deltar i den externa utbildning som erbjuds under AT.`,
        group: 0,
      },
      {
        key: 'f_3',
        label: `Den personliga handledaren planlägger i samråd med AT-läkaren behovet av litteraturstudier och kursdeltagande.`,
        group: 0,
      },
      {
        key: 'f_4',
        label: `Verksamheten har regelbundet återkommande utbildningstillfällen som riktar sig till AT-läkarna och som dessa deltar i.`,
        group: 0,
      },
      {
        key: 'f_5',
        label: `AT-läkaren planlägger i samråd med handledaren litteraturstudier och kursdeltagande.`,
        group: 0,
      },
      {
        key: 'f_6',
        label: `AT-läkarna bidrar till verksamhetens internutbildning genom t.ex. fallpresentationer och artikelreferat.`,
        group: 1,
      },
      {
        key: 'f_7',
        label: `Efter t.ex. genomgången extern kurs förväntas AT-läkaren ge återkoppling till verksamhetens medarbetare vid hemkomsten.`,
        group: 1,
      },
      {
        key: 'f_8',
        label: `Antal timmar som faktiskt utnyttjas för självstudier per vecka.`,
        group: 1,
      },
    ],
  },
  {
    key: 'g',
    label: 'G Utbildningsklimatet',
    checks: [
      {
        key: 'g_1',
        label: `Utbildningsklimatet är utvecklande och inbjuder till diskussioner och frågor.`,
        group: 0,
      },
      {
        key: 'g_2',
        label: `Stödet från seniora kolleger och bakjourer är gott.`,
        group: 0,
      },
      {
        key: 'g_3',
        label: `Det finns förståelse för yngre läkares relativa osäkerhet hos samtliga medarbetare inom verksamheten.`,
        group: 0,
      },
      {
        key: 'g_4',
        label: `Det finns tid för diskussion och reflexion kring t.ex. patientfall.`,
        group: 0,
      },
      {
        key: 'g_5',
        label: `Det finns en kultur som främjar konstruktiv kritik och där misstag och händelser diskuteras öppet.`,
        group: 0,
      },
      {
        key: 'g_6',
        label: `Det finns en acceptans på klinikerna för deltagande i externa utbildningar och studiebesök.`,
        group: 0,
      },
    ],
  },
];

// Från 20230401
const CHECKLIST_ST_2021 = [
  {
    key: 'a',
    label: 'A Verksamheten',
    checks: [
      {
        key: 'a_1',
        label: `Det finns skriftliga riktlinjer från vårdgivaren om hur verksamhetens specialiseringstjänstgöring ska genomföras.`,
        group: 0,
      },
      {
        key: 'a_2',
        label: `Vårdgivaren tillhandahåller skriftliga riktlinjer för hur bedömning av ST-läkarens kompetens ska göras.`,
        group: 0,
      },
      {
        key: 'a_3',
        label: `Tjänstgöringen är tillräckligt allsidig.`,
        group: 0,
      },
      {
        key: 'a_4',
        label: `Det finns rutiner som, utöver extern granskning, anger hur kvaliteten i ST-utbildningen kontinuerligt ska utvärderas och säkerställas.`,
        group: 1,
      },
    ],
  },
  {
    key: 'b',
    label: 'B Medarbetarstab och interna kompetenser',
    checks: [
      {
        key: 'b_1',
        label: `Den huvudansvariga handledaren har ett bevis om specialistkompetens i avsedd specialitet.`,
        group: 0,
      },
      {
        key: 'b_2',
        label: `Samtliga huvudansvariga ST-handledare har genomgått handledarutbildning.`,
        group: 0,
      },
      {
        key: 'b_3',
        label: `Övriga handledare är legitimerade läkare med relevant specialistkompetens. `,
        group: 0,
      },
      {
        key: 'b_4',
        label: `Det finns tillgång till specialistkompetent studierektor, som har genomgått handledarutbildning.`,
        group: 0,
      },
      {
        key: 'b_5',
        label: `Det finns tillgång till handledare i en omfattning som motsvarar behovet av handledning.`,
        group: 0,
      },
      {
        key: 'b_6',
        label: `Det finns tillgång till medarbetare som kan ge nödvändiga instruktioner. `,
        group: 0,
      },
      {
        key: 'b_7',
        label: `Studierektor har relevant specialistkompetens.`,
        group: 1,
      },
      {
        key: 'b_8',
        label: `Det finns generella skriftliga instruktioner för hur handledningen av ST-läkare ska gå till.`,
        group: 2,
      },
      {
        key: 'b_9',
        label: `Handledarna ges minst en gång per år möjlighet till utbildning/uppdatering/kompetensutveckling inom området.`,
        group: 2,
      },
      {
        key: 'b_10',
        label: `Det finns skriftlig uppdragsbeskrivning för studierektorn.`,
        group: 2,
      },
    ],
  },
  {
    key: 'c',
    label: 'C Lokaler och utrustning',
    checks: [
      {
        key: 'c_1',
        label: `Utrustning för diagnostik, utredning och behandling är adekvat.`,
        group: 0,
      },
      {
        key: 'c_2',
        label: `Utrustning för mottagningsverksamhet, möten och behandlingskonferens på distans är adekvat.`,
        group: 0,
      },
      {
        key: 'c_3',
        label: `ST-läkarna har tillgång till funktionell arbetsplats. `,
        group: 0,
      },
      {
        key: 'c_4',
        label: `Det finns tillgång till relevanta IT-baserade kliniska beslutsstöd, sökbar vetenskaplig databas samt stöd från bibliotek eller FoU-enhet under hela specialiseringstjänstgöringen.`,
        group: 0,
      },
    ],
  },
  {
    key: 'd',
    label: 'D Tjänstgöringens upplägg',
    checks: [
      {
        key: 'd_1',
        label: `Utrustning för diagnostik, utredning och behandling är adekvat.`,
        group: 0,
      },
      {
        key: 'd_2',
        label: `Jourtjänstgöring/beredskap utgör en adekvat del av tjänstgöringen.`,
        group: 0,
      },
      {
        key: 'd_3',
        label: `Alla ST-läkare har lika rättigheter, skyldigheter och möjlighet att uppnå målen i målbeskrivningen oavsett kön, könsöverskridande identitet eller uttryck, etnisk tillhörighet, religion eller annan trosuppfattning, funktionsnedsättning, sexuell läggning eller ålder.`,
        group: 0,
      },
      {
        key: 'd_4',
        label: `Verksamhetschefen på det tjänstgöringsställe där en ST-läkare genomför sin huvudsakliga tjänstgöring har det övergripande ansvaret för ST-läkarens ST-utbildning.`,
        group: 1,
      },
      {
        key: 'd_5',
        label: `Den ansvariga verksamhetschefen ansvarar för att det för varje ST-läkare tas fram ett individuellt utbildningsprogram och att det för varje ST-läkare utses en huvudansvarig handledare.`,
        group: 1,
      },
      {
        key: 'd_6',
        label: `Varje ST-läkare har ett utbildningsprogram som utgår från målbeskrivningen och som omfattar de utbildningsaktiviteter som behövs för att uppfylla kompetenskraven i målbeskrivningen.`,
        group: 1,
      },
      {
        key: 'd_7',
        label: `Utbildningsprogrammet följs upp regelbundet och revideras vid behov.`,
        group: 1,
      },
      {
        key: 'd_8',
        label: `Studierektorn samordnar ST-läkarnas interna och externa utbildningsaktiviteter.`,
        group: 1,
      },
      {
        key: 'd_9',
        label: `Studierektorn är en organisatorisk stödfunktion till verksamhetschef, handledare och ST-läkare.`,
        group: 2,
      },
      {
        key: 'd_10',
        label: `I studierektorns arbetsuppgifter ingår att ansvara för att utarbeta introduktionsprogram för ST-läkarna.`,
        group: 2,
      },
      {
        key: 'd_11',
        label: `Studierektorn tar del av kompetensbedömningarna.`,
        group: 2,
      },
      {
        key: 'd_12',
        label: `Alla ST-läkare får introduktion till tjänstgöringen.`,
        group: 3,
      },
      {
        key: 'd_13',
        label: `Leg läkare som arbetar vid enheten med sikte på ST och som avslutat sin BT och/eller BT-läkare som påbörjat sin ST med BT vid enheten har tillgång till individuella utbildningsprogram och handledning i enlighet med ST.`,
        group: 3,
      },
    ],
  },
  {
    key: 'e',
    label: 'E Handledning och kompetensbedömning',
    checks: [
      {
        key: 'e_1',
        label: `Varje ST-läkare ges kontinuerligt handledning i form av stöd och vägledning med utgångspunkt i det individuella utbildningsprogrammet.`,
        group: 0,
      },
      {
        key: 'e_2',
        label: `Det finns en utsedd huvudansvarig handledare för varje ST-läkare.`,
        group: 0,
      },
      {
        key: 'e_3',
        label: `Varje ST-läkare har tillgång till en handledare för varje del av tjänstgöringen.`,
        group: 0,
      },
      {
        key: 'e_4',
        label: `Den huvudansvariga handledaren bedömer ST-läkarens kompetensutveckling kontinuerligt, med utgångspunkt i målbeskrivningen för specialiteten och det individuella utbildningsprogrammet.`,
        group: 0,
      },
      {
        key: 'e_5',
        label: `Det finns fastställda rutiner för bedömningar av ST-läkarens kompetens.`,
        group: 0,
      },
      {
        key: 'e_6',
        label: `Verksamheten har rutiner för hur den kontinuerliga bedömningen dokumenteras (För ST enligt 2015:8 är verksamhetschef ansvarig enligt ovan)`,
        group: 0,
      },
      {
        key: 'e_7',
        label: `Ändamålsenliga metoder används för bedömningar av ST-läkarens kompetens.`,
        group: 1,
      },
      {
        key: 'e_8',
        label: `ST-läkaren bör, utöver handledning, få fortlöpande instruktioner, samt återkoppling från de som har gett instruktionerna.`,
        group: 2,
      },
      {
        key: 'e_9',
        label: `Handledningen är inplanerad i den ordinarie tjänstgöringen för huvudansvarig handledare och ST-läkare.`,
        group: 2,
      },
      {
        key: 'e_10',
        label: `Den huvudansvariga handledaren tjänstgör vid det tjänstgöringsställe där ST-läkaren genomför sin huvudsakliga specialiseringstjänstgöring. Om så inte är fallet ska vårdgivaren på något annat sätt säkerställa att huvudhandledaren är tillgänglig för handledning och även i övrigt har förutsättningar att utföra sitt uppdrag.`,
        group: 2,
      },
      {
        key: 'e_11',
        label: `Varje handledare har genomgått handledarutbildning som tillgodoser kraven enligt föreskriften.`,
        group: 2,
      },
      {
        key: 'e_12',
        label: `Om det finns en risk för att en ST-läkare inte kommer att uppfylla kompetenskraven bör det leda till en åtgärdsplan. En åtgärdsplan bör omfatta justeringar i det individuella utbildningsprogrammet, förändringar i vårdgivarens utbildningsinsatser eller tydligare krav på ST-läkarens insatser.`,
        group: 2,
      },
      {
        key: 'e_13',
        label: `Ändamålsenliga metoder används för bedömningar av ST-läkarens kompetens.`,
        group: 3,
      },
      {
        key: 'e_14',
        label: `Verksamhetschefen eller motsvarande håller regelbundna utvecklingssamtal med ST-läkarna. `,
        group: 3,
      },
      {
        key: 'e_15',
        label: `ST-läkarnas sidoutbildning följs upp.`,
        group: 4,
      },
    ],
  },
  {
    key: 'f',
    label: 'F Teoretisk utbildning',
    checks: [
      {
        key: 'f_1',
        label: `Teoretiska utbildningsmoment och kurser planeras in i enlighet med målbeskrivningen och individuella utbildningsprogrammet.`,
        group: 0,
      },
      {
        key: 'f_2',
        label: `ST-läkarna genomgår den utbildning som planerats i enlighet med målbeskrivningen och det individuella utbildningsprogrammet.`,
        group: 0,
      },
      {
        key: 'f_3',
        label: `Det individuella utbildningsprogrammet innehåller planerade interna utbildningsaktiviteter. `,
        group: 1,
      },
      {
        key: 'f_4',
        label: `Det individuella utbildningsprogrammet innehåller planerade externa utbildningsaktiviteter.`,
        group: 1,
      },
      {
        key: 'f_5',
        label: `Det individuella utbildningsprogrammet innehåller planerad tid för självstudier.`,
        group: 1,
      },
      {
        key: 'f_6',
        label: `Den teoretiska interna utbildningen är systematiskt uppbyggd med koppling till Socialstyrelsens delmål.`,
        group: 2,
      },
    ],
  },
  {
    key: 'g',
    label: `G Medicinsk vetenskap och systematiskt kvalitets- och patientsäkerhetsarbete`,
    checks: [
      {
        key: 'g_1',
        label: `ST-läkarens kompetens inom medicinsk vetenskap bedöms utifrån kunskap kring vetenskapliga metoder och forskningsetiska principer.`,
        group: 0,
      },
      {
        key: 'g_2',
        label: `ST-läkaren bedöms utifrån förmågan att kunna kritiskt granska och värdera vetenskaplig information, samt i sitt arbete ta ansvar för att omsätta och tillämpa denna kunskap.`,
        group: 0,
      },
      {
        key: 'g_3',
        label: `ST-läkaren deltar i systematiskt kvalitets- och patientsäkerhetsarbete i sådan utsträckning att förutsättningar för delmåluppfyllelse föreligger. `,
        group: 0,
      },
      {
        key: 'g_4',
        label: `Utbildning erbjuds för att uppnå ett medicinskt vetenskapligt syn- och förhållningssätt.`,
        group: 1,
      },
      {
        key: 'g_5',
        label: `Det finns goda förutsättningar för genomförande av ett skriftligt individuellt arbete enligt vetenskapliga principer.`,
        group: 1,
      },
      {
        key: 'g_6',
        label: `Det finns goda förutsättningar för att genomföra, dokumentera och redovisa ett kvalitetsarbete.`,
        group: 1,
      },
      {
        key: 'g_7',
        label: `ST-läkaren ges möjlighet till utbildning inom patientsäkerhetsarbete och systematiskt kvalitetsarbete.`,
        group: 2,
      },
      {
        key: 'g_8',
        label: `Under det senaste året har av ST-läkare genomförda arbeten publicerats och/eller presenterats (av ST-läkaren) vid vetenskapliga/professionella sammankomster.`,
        group: 3,
      },
      {
        key: 'g_9',
        label: `Det finns tillgång till ett välfungerande doktorandprogram.`,
        group: 3,
      },
      {
        key: 'g_10',
        label: `Enheten har regelbundna möten där medarbetare diskuterar och kritiskt granskar vetenskaplig litteratur.`,
        group: 3,
      },
      {
        key: 'g_11',
        label: `ST-läkare ges möjlighet att medverka i forskningsprojekt under arbetstid.`,
        group: 4,
      },
    ],
  },
  {
    key: 'h',
    label: 'H Ledarskapskompetens och kommunikativ kompetens',
    checks: [
      {
        key: 'h_1',
        label: `ST-läkaren ges förutsättningar att kontinuerligt utveckla ledarskapskompetens i enlighet med målbeskrivningen.`,
        group: 0,
      },
      {
        key: 'h_2',
        label: `ST-läkaren bedöms kontinuerligt i medicinskt ledarskap och multiprofessionellt samarbete och återkoppling sker.`,
        group: 0,
      },
      {
        key: 'h_3',
        label: `ST-läkaren genomgår utbildning i hälso- och sjukvårdens förutsättningar i enlighet med målbeskrivning.`,
        group: 0,
      },
      {
        key: 'h_4',
        label: `ST-läkaren ges kontinuerlig handledning i kommunikativ kompetens.`,
        group: 0,
      },
      {
        key: 'h_5',
        label: `ST-läkarens kommunikativa kompetens bedöms fortlöpande och återkoppling sker.`,
        group: 0,
      },
      {
        key: 'h_6',
        label: `ST-läkaren bereds möjlighet att planera och genomföra undervisning. `,
        group: 0,
      },
      {
        key: 'h_7',
        label: `ST-läkarens kompetens i undervisning bedöms fortlöpande och återkoppling sker kontinuerligt. `,
        group: 0,
      },
      {
        key: 'h_8',
        label: `ST-läkaren bereds möjlighet att handleda medarbetare och studenter. `,
        group: 0,
      },
      {
        key: 'h_9',
        label: `ST-läkarens kompetens i handledning bedöms fortlöpande och återkoppling sker kontinuerligt. `,
        group: 0,
      },
      {
        key: 'h_10',
        label: `ST-läkaren bereds möjlighet att undervisa under handledning.`,
        group: 1,
      },
      {
        key: 'h_11',
        label: `ST-läkaren bereds möjlighet att själv handleda under handledning.`,
        group: 1,
      },
      {
        key: 'h_12',
        label: `ST-läkaren bereds möjlighet att delta i reflektion i grupp i enlighet med målbeskrivningen.`,
        group: 1,
      },
      {
        key: 'h_13',
        label: `Det finns möjlighet att auskultera hos chefer och ledare inom organisationen.`,
        group: 2,
      },
    ],
  },
];

const CHECKLIST_ST = [
  {
    key: 'a',
    label: 'A Verksamheten',
    checks: [
      {
        key: 'a_1',
        label: `Skriftliga riktlinjer från vårdgivaren om hur specialiseringstjänstgöringen ska genomföras finns.`,
        group: 0,
      },
      {
        key: 'a_2',
        label: `Skriftliga riktlinjer från vårdgivaren om hur specialiseringstjänstgöringen ska utvärderas finns.`,
        group: 0,
      },
      {
        key: 'a_3',
        label: `Tjänstgöringen är tillräckligt allsidig.`,
        group: 0,
      },
    ],
  },
  {
    key: 'b',
    label: 'B Medarbetarstab och interna kompetenser',
    checks: [
      {
        key: 'b_1',
        label: `Samtliga huvudansvariga ST-handledare har specialistkompetens i avsedd specialitet.`,
        group: 0,
      },
      {
        key: 'b_2',
        label: `Samtliga huvudansvariga ST-handledare har genomgått handledarutbildning.`,
        group: 0,
      },
      {
        key: 'b_3',
        label: `Tillgång till specialistkompetent studierektor med handledarutbildning finns.`,
        group: 0,
      },
      {
        key: 'b_4',
        label: `Tillräckligt många läkare med relevant specialitet och kompetens finns för att alla ST ska få adekvat handledning.`,
        group: 0,
      },
      {
        key: 'b_5',
        label: `Tillräckligt många läkare och andra medarbetare med relevant kompetens finns för att instruktioner ska kunna ges.`,
        group: 0,
      },
      {
        key: 'b_6',
        label: `Studierektorn har relevant specialistkompetens.`,
        group: 1,
      },
      {
        key: 'b_7',
        label: `Generella skriftliga instruktioner för ST-handledning finns.`,
        group: 2,
      },
      {
        key: 'b_8',
        label: `Regelbundna handledarträffar för ST-handledarna finns.`,
        group: 2,
      },
      {
        key: 'b_9',
        label: `Skriftlig uppdragsbeskrivning för SR finns.`,
        group: 2,
      },
    ],
  },
  {
    key: 'c',
    label: 'C Lokaler och utrustning',
    checks: [
      {
        key: 'c_1',
        label: `Adekvat utrustning för diagnostik, utredning och behandling finns.`,
        group: 0,
      },
      {
        key: 'c_2',
        label: `ST-läkarna har tillgång till funktionell arbetsplats.`,
        group: 1,
      },
      {
        key: 'c_3',
        label: `Tillgång till IT-baserade kliniska beslutsstöd, sökbar vetenskaplig databas, stöd från bibliotek eller FoU-enhet finns.`,
        group: 1,
      },
    ],
  },
  {
    key: 'd',
    label: 'D Tjänstgöringens uppläggning',
    checks: [
      {
        key: 'd_1',
        label: `Det finns skriftliga riktlinjer för hur målbeskrivningen ska uttolkas samt styrande dokument för tjänstgöringens upplägg.`,
        group: 0,
      },
      {
        key: 'd_2',
        label: `Jourtjänstgöring/beredskap uppfyller kraven i målbeskrivningen.`,
        group: 0,
      },
      {
        key: 'd_3',
        label: `Alla ST-läkare har individuellt utbildningsprogram som utgår från kraven i målbeskrivningen.`,
        group: 1,
      },
      {
        key: 'd_4',
        label: `Det individuella utbildningsprogrammet följs regelbundet upp och revideras vid behov i samråd med SR, HL och ST-läkaren.`,
        group: 1,
      },
      {
        key: 'd_5',
        label: `SR bör utgöra en organisatorisk stödfunktion till verksamhetschef, handledare och ST-läkare.`,
        group: 2,
      },
      {
        key: 'd_6',
        label: `SR bör medverka till utarbetning av ST-läkarnas introduktionsprogram.`,
        group: 2,
      },
      {
        key: 'd_7',
        label: `SR bör ta del av verksamhetschefens och handledarens bedömning av ST-läkaren.`,
        group: 2,
      },
      {
        key: 'd_8',
        label: `Alla ST-läkare får introduktion till tjänstgöringen.`,
        group: 3,
      },
      {
        key: 'd_9',
        label: `Tillgång till individuella utbildningsprogram och handledning för de leg läkare utan specialistkompetens som arbetar vid enheten med siktet inställt på ST finns.`,
        group: 3,
      },
      {
        key: 'd_10',
        label: `Alla ST-läkare har lika rättigheter, skyldigheter och möjligheter att uppnå målen i mål-beskrivningen oavsett kön, könsöverskridande identitet eller uttryck, etnicitet, religion eller annan trosuppfattning, funktionsnedsättning, sexuell läggning eller ålder.`,
        group: 3,
      },
    ],
  },
  {
    key: 'e',
    label: 'E Handledning och uppföljning',
    checks: [
      {
        key: 'e_1',
        label: `Handledningen utgår från det individuella utbildningsprogrammet.`,
        group: 0,
      },
      {
        key: 'e_2',
        label: `Det finns en utsedd huvudansvarig handledare för varje ST.`,
        group: 0,
      },
      {
        key: 'e_3',
        label: `ST-läkaren har tillgång till handledare under varje utbildningsperiod.`,
        group: 0,
      },
      {
        key: 'e_4',
        label: `Fortlöpande bedömning av ST-läkarens kompetensutveckling utifrån målbeskrivningen och utbildningsprogrammet görs under hela ST.`,
        group: 0,
      },
      {
        key: 'e_5',
        label: `Kontinuerlig bedömning utförs av verksamhetschef (motsvarande) och huvudsaklig handledare.`,
        group: 0,
      },
      {
        key: 'e_6',
        label: `Verksamhetschef (motsvarande) ansvarar för att den kontinuerliga bedömningen dokumenteras.`,
        group: 0,
      },
      {
        key: 'e_7',
        label: `ST-läkaren bör, utöver handledning, få fortlöpande instruktioner.`,
        group: 1,
      },
      {
        key: 'e_8',
        label: `Handledningen är inplanerad i ordinarie tjänstgöringsschema.`,
        group: 1,
      },
      {
        key: 'e_9',
        label: `Den huvudansvariga handledaren tjänstgör där ST-läkaren huvudsakligen genomför sin specialiseringstjänstgöring.`,
        group: 1,
      },
      {
        key: 'e_10',
        label: `Handledarutbildningen bör omfatta handledning, pedagogik, metoder för bedömning, kommunikation och etik.`,
        group: 1,
      },
      {
        key: 'e_11',
        label: `Den fortlöpande bedömningen görs med på förhand kända och överenskomna metoder.`,
        group: 1,
      },
      {
        key: 'e_12',
        label: `Påvisade brister i ST-läkarens kompetens leder till en åtgärdsplan.`,
        group: 1,
      },
      {
        key: 'e_13',
        label: `Verksamhetschefen eller motsvarande håller regelbundna utvecklingssamtal med ST-läkarna.`,
        group: 1,
      },
      {
        key: 'e_14',
        label: `ST-läkarnas sidoutbildning följs upp.`,
        group: 2,
      },
      {
        key: 'e_15',
        label: `Varje enhet har en plan för hur ST-läkare som riskerar hamna i svårighet för att uppnå enskilda delmål, sin professionella utveckling generellt eller av personliga skäl, ska kunna hjälpas.`,
        group: 2,
      },
    ],
  },
  {
    key: 'f',
    label: 'F Teoretisk utbildning',
    checks: [
      {
        key: 'f_1',
        label: `Teoretiska utbildningsmoment och kurser planeras in i enlighet med målbeskrivningen.`,
        group: 0,
      },
      {
        key: 'f_2',
        label: `ST-läkarna genomgår den teoretiska utbildning som definieras i det individuella utbildningsprogrammet.`,
        group: 0,
      },
      {
        key: 'f_3',
        label: `SR samordnar ST-läkarnas interna och externa utbildning.`,
        group: 1,
      },
      {
        key: 'f_4',
        label: `Intern utbildning planeras in i det individuella utbildningsprogrammet.`,
        group: 2,
      },
      {
        key: 'f_5',
        label: `Extern utbildning planeras in i det individuella utbildningsprogrammet.`,
        group: 2,
      },
      {
        key: 'f_6',
        label: `Tid för regelbundna självstudier planeras in i det individuella utbildningsprogrammet.`,
        group: 2,
      },
    ],
  },
  {
    key: 'g',
    label: 'G Medicinsk vetenskap och kvalitetsarbete',
    checks: [
      {
        key: 'g_1',
        label: `Utbildning erbjuds för att uppnå ett medicinskt vetenskapligt syn- och förhållningssätt.`,
        group: 0,
      },
      {
        key: 'g_2',
        label: `Goda förutsättningar finns för genomförande av ett skriftligt individuellt arbete enligt vetenskapliga principer.`,
        group: 0,
      },
      {
        key: 'g_3',
        label: `ST-läkarnas kompetens i medicinsk vetenskap bedöms fortlöpande och återkoppling sker.`,
        group: 0,
      },
      {
        key: 'g_4',
        label: `ST-läkarna ges möjlighet att utveckla kunskap om och kompetens i kvalitets- och patientsäkerhetsarbete.`,
        group: 0,
      },
      {
        key: 'g_5',
        label: `Goda förutsättningar finns för att genomföra, dokumentera och redovisa ett kvalitetsarbete.`,
        group: 0,
      },
      {
        key: 'g_6',
        label: `ST-läkarens kompetens i kvalitetsutveckling bedöms fortlöpande och återkoppling sker.`,
        group: 0,
      },
      {
        key: 'g_7',
        label: `ST-läkares genomförda individuella arbeten publiceras eller presenteras vid vetenskapliga möten.`,
        group: 1,
      },
      {
        key: 'g_8',
        label: `Tillgång till ett välfungerande doktorandprogram finns.`,
        group: 1,
      },
      {
        key: 'g_9',
        label: `Enheten har regelbundna möten där medarbetare diskuterar och kritiskt granskar vetenskaplig litteratur.`,
        group: 1,
      },
      {
        key: 'g_10',
        label: `Minst ett, på enheten utfört, självständigt forskningsprojekt har publicerats i vetenskaplig tidskrift eller presenterats vid vetenskapligt möte de senaste två åren.`,
        group: 1,
      },
    ],
  },
  {
    key: 'h',
    label: 'H Ledarskapskompetens och kommunikativ kompetens',
    checks: [
      {
        key: 'h_1',
        label: `ST-läkaren ges förutsättningar att utveckla ledarskapskompetens i enlighet med målbeskrivningen.`,
        group: 0,
      },
      {
        key: 'h_2',
        label: `ST-läkaren ges kontinuerlig handledning i ledarskapskompetens.`,
        group: 0,
      },
      {
        key: 'h_3',
        label: `ST-läkarens kompetens i ledarskap bedöms fortlöpande och med återkoppling.`,
        group: 0,
      },
      {
        key: 'h_4',
        label: `ST-läkaren ges förutsättningar att utveckla den kommunikativa kompetensen såväl muntligt som skriftligt i enlighet med målbeskrivningen.`,
        group: 0,
      },
      {
        key: 'h_5',
        label: `ST-läkaren ges kontinuerlig handledning i kommunikativ kompetens.`,
        group: 0,
      },
      {
        key: 'h_6',
        label: `ST-läkarens kommunikativa kompetens bedöms och återkoppling sker.`,
        group: 0,
      },
      {
        key: 'h_7',
        label: `ST-läkaren bereds möjlighet att undervisa under handledning.`,
        group: 1,
      },
      {
        key: 'h_8',
        label: `ST-läkaren bereds möjlighet att själv handleda under handledning.`,
        group: 1,
      },
      {
        key: 'h_9',
        label: `ST-läkaren bereds möjlighet att deltaga i inom- eller mellanprofessionell reflektion i grupp.`,
        group: 1,
      },
    ],
  },
];

export const CHECKLIST_INDEX_LABELS = {
  at: {
    at_b: CHECKLIST_AT_OVERGRIPANDE,
    at: CHECKLIST_AT,
  },
  st: {
    st_2021: CHECKLIST_ST_2021,
    st: CHECKLIST_ST,
  },
};

import fragment from '~/api/fragment/listFilter';
import getListFilterQuery from '~/api/query/listFilter';

const resolvers = {
  Mutation: {
    setListFilter: (_, variables, { cache, getCacheKey }) => {
      const id = getCacheKey({
        __typename: 'ListFilter',
        id: variables.id,
      });

      const listFilter = cache.readFragment({
        id,
        fragment,
      });

      const updated = cache.writeQuery({
        query: getListFilterQuery,
        data: {
          getListFilter: {
            ...listFilter,
            filter: variables.filter,
            sort: variables.sort,
            page: variables.page,
            perPage: variables.perPage,
          },
        },
        variables: {
          id,
        },
      });

      return updated;
    },
  },
};

export default resolvers;

import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';

import usePrevious from '~/hooks/usePrevious';
import {
  appOrganizationQuery,
  appTaxonomyQuery,
  appTermQuery,
  appUserQuery,
  appUserRoleQuery,
} from './query/appData';

import {
  courseLevelCountVar,
  courseProfessionCountVar,
  courseSpecialityCountVar,
} from '~/core/apolloClient';
import { ORIGIN } from '~/core/constants';

const useFetchAllType = (query, type, perPage) => {
  const [page, setPage] = useState(1);
  const previousPage = usePrevious(page);

  const { loading, error, data, fetchMore } = useQuery(query, {
    variables: {
      page,
      perPage,
    },
  });

  const loaded = (data && data[type]) || {};
  const { items = [], pageInfo } = loaded || {};
  const { hasNextPage = false, itemCount = 0 } = pageInfo || {};
  const results = useMemo(() => items.map(i => i._source), [items]);

  const loadMore = useCallback(() => {
    fetchMore({
      query,
      //notifyOnNetworkStatusChange: true,
      variables: {
        page,
        perPage,
      },
      skip: !hasNextPage,
    });
  }, [query, page, perPage, hasNextPage, fetchMore]);

  useEffect(() => {
    if (!loading && hasNextPage) {
      setPage(page => page + 1);
    }
  }, [loading, hasNextPage]);

  useEffect(() => {
    if (page !== 1 && page !== previousPage) {
      loadMore();
    }
  }, [page, previousPage, loadMore]);

  return {
    data: {
      count: itemCount,
      results,
    },
    loading,
    error,
  };
};

// App data
export const useAppData = () => {
  const { course: courseOrigin } = ORIGIN;

  const { data: taxonomyData, loading: taxonomyLoading } = useFetchAllType(
    appTaxonomyQuery,
    'taxonomy',
    100,
  );

  const { data: termData, loading: termLoading } = useFetchAllType(
    appTermQuery,
    'term',
    1000,
  );

  const { data: userData, loading: userLoading } = useFetchAllType(
    appUserQuery,
    'user',
    1000,
  );

  const { data: userRoleData, loading: userRoleLoading } = useFetchAllType(
    appUserRoleQuery,
    'userRole',
    100,
  );

  const {
    data: organizationData,
    loading: organizationLoading,
  } = useFetchAllType(appOrganizationQuery, 'organization', 1000);

  const loading = useMemo(
    () =>
      taxonomyLoading ||
      termLoading ||
      userLoading ||
      userRoleLoading ||
      organizationLoading,
    [
      taxonomyLoading,
      termLoading,
      userLoading,
      userRoleLoading,
      organizationLoading,
    ],
  );

  const courseTerms = useMemo(
    () => termData?.results.filter(t => t.origin.includes(courseOrigin)),
    [termData, courseOrigin],
  );

  // Count all speciality terms specific to 'kursverktyg' for displaying 'all'
  const courseSpecialityCount = useMemo(
    () => courseTerms?.filter(t => t.taxonomy === 'speciality').length,
    [courseTerms],
  );

  // Count all profession terms specific to 'kursverktyg' for displaying 'all'
  const courseProfessionCount = useMemo(
    () => courseTerms?.filter(t => t.taxonomy === 'profession').length,
    [courseTerms],
  );

  const courseLevelCount = useMemo(
    () => courseTerms?.filter(t => t.taxonomy === 'course_level').length,
    [courseTerms],
  );

  courseSpecialityCountVar(courseSpecialityCount);
  courseProfessionCountVar(courseProfessionCount);
  courseLevelCountVar(courseLevelCount);

  return {
    loading,
    taxonomy: taxonomyData,
    term: termData,
    user: userData,
    userRole: userRoleData,
    organization: organizationData,
  };
};

export default useAppData;

const breakpoints = ['576px', '768px', '992px', '1200px', '1400px'];

const [small, medium, large, xlarge, xxlarge] = breakpoints;
// aliases
breakpoints.sm = small;
breakpoints.md = medium;
breakpoints.lg = large;
breakpoints.xl = xlarge;
breakpoints.xxl = xxlarge;

export const device = {
  small: `(min-width: ${breakpoints.sm})`,
  medium: `(min-width: ${breakpoints.md})`,
  large: `(min-width: ${breakpoints.lg})`,
  xlarge: `(min-width: ${breakpoints.xl})`,
  xxlarge: `(min-width: ${breakpoints.xxl})`,
};

export default breakpoints;

const space = [
  0, // 0
  2, // 1
  4, // 2
  8, // 3
  16, // 4
  20, // 5
  24, // 6
  32, // 7
  48, // 8
  64, // 9
  80, // 10
];

const [, , , , , siteGutter] = space; // idx 5 = 20px
space.siteGutter = siteGutter;
space.halfGutter = siteGutter / 2;
space.dubbleGutter = siteGutter * 2;
space.bleed = -siteGutter;
space.bleedHalfGutter = -(siteGutter / 2);

/* Above px values translated to rem */
const remScale = [
  0, // 0
  '0.125rem', // 1
  '0.25rem', // 2
  '0.5rem', // 3
  '1rem', // 4
  '1.25rem', // 5
  '1.5rem', // 6
  '2rem', // 7
  '3rem', // 8
  '4rem', // 9
  '5rem', // 10
];

export { remScale };

export default space;

import React from 'react';
import PropTypes from 'prop-types';

import AppContext from './AppContext';
import MediaQueryProvider from './MediaQueryProvider';

function App({ children, context }) {
  return (
    <AppContext.Provider value={{ context }}>
      <MediaQueryProvider>{React.Children.only(children)}</MediaQueryProvider>
    </AppContext.Provider>
  );
}

App.propTypes = {
  context: PropTypes.shape({
    // Universal HTTP client
    loggedIn: PropTypes.bool.isRequired,
    fetch: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    query: PropTypes.object,
  }).isRequired,
  children: PropTypes.element.isRequired,
};

export default App;

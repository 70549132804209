import { gql } from '@apollo/client';

const typeDefs = gql`
  type ListFilter {
    id: String!
    filter: JSON
    sort: [JSON]
    page: Int
    perPage: Int
  }

  type Query {
    getListFilter(id: String!): ListFilter!
  }

  type Mutation {
    setListFilter(id: String!, filter: JSON): ListFilter!
  }
`;

export default typeDefs;

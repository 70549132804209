const sizes = {
  container: {
    mini: 270,
    small: 540,
    medium: 720,
    large: 960,
    xlarge: 1140,
    xxlarge: 1400,
    fluid: 'none',
  },
};

export default sizes;

import Cookies from 'js-cookie';

import {
  acl_path,
  auth_path,
  cookie_name,
  getUrlBase,
  readToken,
  validate_path,
} from '~/server/middleware';

const day_in_seconds = 86400;
const day_in_miliseconds = day_in_seconds * 1000;

let verified = false;

const getArgs = params => ({
  method: 'POST',
  cache: 'no-cache',
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
  body: JSON.stringify(params),
});

const maybeError = response => {
  const { code, message } = response;

  if (code === 'incorrect_password') {
    return {
      isLoggedIn: false,
      message,
    };
  }
};

const getCookie = () => {
  const cookies = Cookies.get();
  return cookies[cookie_name];
};

const setCookie = data => {
  const inSevenDays = new Date(new Date().getTime() + 7 * day_in_miliseconds);
  Cookies.set(cookie_name, data, {
    expires: inSevenDays,
    sameSite: 'lax',
  });
};

const removeCookie = () => {
  Cookies.remove(cookie_name, { path: '/' });
};

export const doLogout = () => {
  removeCookie();
};

export const doLogin = async (email, password) => {
  let url_base = getUrlBase('spurnet');

  const args = getArgs({
    username: email,
    password,
  });

  let response = await window.fetch(`${url_base}${auth_path}`, args);
  response = await response.json();

  let error = maybeError(response);

  if (error) {
    return error;
  }

  let { token } = response;

  // Query next source
  if (!token) {
    url_base = getUrlBase('kursverktyg');
    response = await window.fetch(`${url_base}${auth_path}`, args);
    response = await response.json();

    error = maybeError(response);

    if (error) {
      return error;
    }

    token = response?.token;
  }

  if (!token) {
    return {
      message: 'Felaktig inloggning',
      isLoggedIn: false,
    };
  }

  const {
    data: {
      user: { id: userId },
    },
  } = readToken(token);

  // Validate acf
  response = await window.fetch(`${url_base}${acl_path}/${userId}`, {
    method: 'GET',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  response = await response.json();

  // ACL is OK set cookie
  if (response === true) {
    setCookie(token);
    verified = true;
    return {
      message: null,
      isLoggedIn: true,
    };
  }

  return {
    message: 'Okänt fel',
    isLoggedIn: false,
  };
};

// Validate token
const validateLogin = async (url, token) => {
  let response = await window.fetch(`${url}${validate_path}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  response = await response.json();

  return response;
};

export const checkLogin = async () => {
  if (verified) {
    return true;
  }

  const token = getCookie();

  if (!token) {
    return false;
  }

  const { iss } = readToken(token);

  if (!iss) {
    return false;
  }

  const {
    code,
    data: { status },
  } = await validateLogin(iss, token);

  const valid = status === 200 && code === 'jwt_auth_valid_token';

  if (valid) {
    verified = true;
  } else {
    removeCookie();
    verified = false;
  }

  return valid;
};

import React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import ReactDOM from 'react-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import 'whatwg-fetch';
// TODO emotion styling of @rebass/forms not working
// import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { ApolloProvider } from '@apollo/client';
import { createPath } from 'history';
import queryString from 'query-string';

import MainApp from './components/App';
import GlobalStyle from './components/GlobalStyle';
import NoAuthApp from './components/NoAuthApp';
import { updateMeta } from './core/DOMUtils';
import { initializeApollo } from './core/apolloClient';
import config from './core/config';
import createFetch from './core/createFetch';
import history from './core/history';
import theme from './core/theme';
import router from './router';
import { checkLogin } from './session';

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

// Global (context) variables that can be easily accessed from any React
// component
// https://facebook.github.io/react/docs/context.html
const context = {
  loggedIn: false,
  // Universal HTTP client
  fetch: createFetch(fetch, {
    baseUrl: config.apiUrl,
  }),
};

const container = document.getElementById('root');
let currentLocation = history.location;

const scrollPositionsHistory = {};

// Re-render the app when window.location changes
const onLocationChange = async ({ location, action }) => {
  // Remember the latest scroll position for the previous location
  scrollPositionsHistory[currentLocation.key] = {
    scrollX: window.pageXOffset,
    scrollY: window.pageYOffset,
  };
  // Delete stored scroll position for next page if any
  if (action === 'PUSH') {
    delete scrollPositionsHistory[location.key];
  }
  currentLocation = location;

  const isInitialRender = !action;
  try {
    context.pathname = location.pathname;
    context.query = queryString.parse(location.search);

    // Check login
    context.loggedIn = await checkLogin();

    // Traverses the list of routes in the order they are defined until
    // it finds the first route that matches provided URL path string
    // and whose action method returns anything other than `undefined`.
    const route = await router.resolve(context);

    // Prevent multiple page renders during the routing process
    if (currentLocation.key !== location.key) {
      return;
    }

    if (route.redirect) {
      history.replace(route.redirect);
      return;
    }

    const client = initializeApollo();

    const App = context.loggedIn ? MainApp : NoAuthApp;

    // eslint-disable-next-line max-len
    // const renderReactApp = isInitialRender ? ReactDOM.hydrate : ReactDOM.render;

    ReactDOM.render(
      <React.StrictMode>
        <ApolloProvider client={client}>
          <StyledThemeProvider theme={theme}>
            <GlobalStyle />
            <App context={context}>{route.component}</App>
          </StyledThemeProvider>
        </ApolloProvider>
      </React.StrictMode>,
      container,
      () => {
        if (isInitialRender) {
          // Switch off the native scroll restoration behavior and handle it
          // manually
          // https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
          if (window.history && 'scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
          }

          const elem = document.getElementById('css');
          if (elem) elem.parentNode.removeChild(elem);
          return;
        }

        document.title = route.title;

        updateMeta('description', route.description);
        // Update necessary tags in <head> at runtime here, ie:
        // updateMeta('keywords', route.keywords);
        // updateCustomMeta('og:url', route.canonicalUrl);
        // updateCustomMeta('og:image', route.imageUrl);
        // updateLink('canonical', route.canonicalUrl);
        // etc.

        let scrollX = 0;
        let scrollY = 0;
        const pos = scrollPositionsHistory[location.key];
        if (pos) {
          scrollX = pos.scrollX;
          scrollY = pos.scrollY;
        } else {
          const targetHash = location.hash.substr(1);
          if (targetHash) {
            const target = document.getElementById(targetHash);
            if (target) {
              scrollY = window.pageYOffset + target.getBoundingClientRect().top;
            }
          }
        }

        // Restore the scroll position if it was saved into the state
        // or scroll to the given #hash anchor
        // or scroll to top of the page
        window.scrollTo(scrollX, scrollY);

        // Google Analytics tracking. Don't send 'pageview' event after
        // the initial rendering, as it was already sent
        if (window.ga) {
          window.ga('send', 'pageview', createPath(location));
        }
      },
    );
  } catch (error) {
    if (isDev) {
      throw error;
    }

    console.error(error);

    // Do a full page reload if error occurs during client-side navigation
    if (!isInitialRender && currentLocation.key === location.key) {
      console.error('RSK will reload your page after error');
      window.location.reload();
    }
  }
};

// Handle client-side navigation by using HTML5 History API
// For more information visit https://github.com/mjackson/history#readme
history.listen(onLocationChange);
onLocationChange({ location: currentLocation });

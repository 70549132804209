const colors = {
  body: '#f3f1ef',
  white: '#fff',
  base: '#222',
  primary: '#ad211d', // Red
  secondary: '#133244', // Dark blue
  tertiary: '#22809a', // Light blue
  highlight: 'rgba(255, 195, 193, 0.5)', // Pink
  border: '#ddd',
  borderLight: '#f2f2f2',
  textMeta: '#777',
  textMetaLight: '#9b9b9b',
  textLink: '#22809a',
  background: 'rgba(205, 212, 221, 0.2)',
  boilerplate: '#f0f2f4',
  overlay: 'rgba(0, 0, 0, 0.9)',
  statusBlue: '#22809a', // Blue
  statusGray: '#cecece', // Gray
  statusGreen: '#a1d280', // Green
  statusOrange: '#ffc25d', // Orange
  statusRed: '#e75f55', // Red
  statusYellow: '#ffba44', // Yellow
  success: '#6ca82a',
};

export default colors;

import colors from './colors';

const borders = [0, '1px solid', '2px solid'];

const radii = [0, 2, 4, 6, 10, 20];
radii.circle = '50%';

const shadows = [
  '0 3px 5px 0 rgba(0, 0, 0, 0.05)',
  '0 5px 10px 0 rgba(0, 0, 0, 0.075)',
  `inset 0 -1px ${colors.border}`,
];

const [, card, underline] = shadows;

shadows.card = card;
shadows.tab = underline;

export { borders, radii, shadows };

import { createGlobalStyle } from 'styled-components';

import theme from '../core/theme';

// https://hankchizljaw.com/wrote/a-modern-css-reset/
const aModernCssReset = `
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default padding */
  /*
  ul[class],
  ol[class] {
    padding: 0;
  }
  */

  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  /* Set core body defaults */
  body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
  }

  /* Remove list styles on ul, ol elements with a class attribute */
  /*
  ul[class],
  ol[class] {
    list-style: none;
  }
  */

  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  /* Make images easier to work with */
  img {
    max-width: 100%;
    display: block;
  }

  img,
  svg {
    vertical-align: middle;
  }

  /* Natural flow and rhythm in articles by default */
  article > * + * {
    margin-top: 1em;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  /* Remove all animations and transitions for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }
`;

// https://github.com/twbs/bootstrap/blob/main/scss/_reboot.scss

const interactionAndFormCss = `
  [tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
  }

  label {
    display: inline-block;
  }

  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
  }

  button,
  input {
    overflow: visible;
  }

  input[readonly] {
    color: #999;
  }

  button,
  select {
    text-transform: none;
  }

  button {
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 0;
  }

  button:focus,
  input:focus,
  textarea:focus {
    box-shadow: none;
    outline: none;
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  [role="button"] {
    cursor: pointer;
  }

  select {
    word-wrap: normal;
  }

  textarea {
    overflow: auto;
    resize: vertical;
  }

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: textfield;
  }

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }

  ::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  ::-webkit-datetime-edit-fields-wrapper,
  ::-webkit-datetime-edit-text,
  ::-webkit-datetime-edit-minute,
  ::-webkit-datetime-edit-hour-field,
  ::-webkit-datetime-edit-day-field,
  ::-webkit-datetime-edit-month-field,
  ::-webkit-datetime-edit-year-field {
    padding: 0;
  }

  ::-webkit-inner-spin-button {
    height: auto;
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: ${theme.colors.base};
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: ${theme.colors.base};
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: ${theme.colors.base};
  }
  :-moz-placeholder { /* Firefox 18- */
    color: ${theme.colors.base};
  }

  /* Selection */
  ::-moz-selection {
    background: ${theme.colors.highlight};
    text-shadow: none;
  }

  ::selection {
    background: ${theme.colors.highlight};
    text-shadow: none;
  }
`;

const tableCss = `
  table {
    border: solid 1px ${theme.colors.border};
    background-color: ${theme.colors.white};
    caption-side: bottom;
    border-collapse: collapse;
  }

  td a {
    color: ${theme.colors.textLink};
    font-weight: 600;
  }

  thead th,
  tr:nth-child(even) {
    background-color: ${theme.colors.background};
  }

  th {
    color: ${theme.colors.textMeta};
    font-weight: 700;
    text-align: inherit;
    text-align: -webkit-match-parent;
    white-space: nowrap;
  }

  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }
`;

const elementDisplay = `
  article,
  main,
  details {
    display: block;
  }

  h1,
  h2,
  h3,
  h4 {
    text-rendering: optimizelegibility;
  }

  a {
    color: inherit;
    text-decoration: inherit;
    transition: background-color 300ms ease, color 300ms ease;
  }

  hr {
    border: 0;
    margin: 0;
    height: 1px;
    background-color: ${theme.colors.border};
  }

  output {
    display: inline-block;
  }

  iframe {
    border: 0;
  }

  summary {
    display: list-item;
    cursor: pointer;
  }

  progress {
    vertical-align: baseline;
  }

  [hidden] {
    display: none !important;
  }
`;

const appDefaultCss = `
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    background-color: ${theme.colors.body};
    color: ${theme.colors.base};
    font-family: ${theme.fonts.primary};
    font-weight: ${theme.fontWeights.regular};
    line-height: ${theme.lineHeights.base};
    text-align: left;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  #root {
    height: 100%;
    width: 100%;
  }
`;

const printCss = `
  @media print {
    * {
      background-color: white !important;
    }

    html {
      margin: 0 !important;
    }

    body {
      margin: 1rem !important;
    }

    header,
    footer,
    *[data-hidden-print="true"] {
      display: none !important;
    }

    *[data-print-unset="true"] {
      border: 0 !important;
      margin: 0 !important;
      padding: 0 !important;
    }

    *[data-print-page-break-before="true"] {
      page-break-before: always !important;
    }
  }
`;

export default createGlobalStyle`
  ${aModernCssReset}
  ${interactionAndFormCss}
  ${tableCss}
  ${elementDisplay}
  ${appDefaultCss}
  ${printCss}
`;

import React from 'react';
import { Flex, Box } from 'rebass/styled-components';
import Animation from 'react-spinners/PuffLoader';
// import Animation from 'react-spinners/RingLoader';

import { useMediaQueryContext } from '~/components/MediaQueryProvider';

const Loader = () => {
  const { mediumMinView } = useMediaQueryContext();
  const loaderSize = mediumMinView ? '150px' : '72px';

  return (
    <Box my="7">
      <Flex justifyContent="center" alignItems="center">
        <Animation color="#22809a" size={loaderSize} />
      </Flex>
    </Box>
  );
};

export default Loader;

import { gql } from '@apollo/client';

const fragment = gql`
  fragment ListFilter on ListFilter {
    __typename
    id
    filter
    sort
    page
    perPage
  }
`;

export default fragment;

const regular = 400;
const medium = 500;
const semiBold = 600;
const bold = 700;

export const fontWeights = {
  base: regular,
  regular,
  medium,
  semiBold,
  bold,
};

const OpenSans = [
  'Open Sans',
  'Helvetica Neue',
  'Helvetica',
  'Arial',
  'Lucida Grande',
  'sans-serif',
]
  .map(font => `'${font}'`)
  .join(', ');

const SpaceMono = ['Space Mono', 'monospace']
  .map(font => `'${font}'`)
  .join(', ');

export const fonts = {
  primary: OpenSans,
  monospace: SpaceMono,
  heading: OpenSans,
};

export const fontSizes = [
  11, // 0
  13, // 1
  14, // 2
  15, // 3
  17, // 4
  21, // 5
  24, // 6
  34, // 7
  46, // 8
  64, // 9
  92, // 10
];

export const lineHeights = {
  base: 1.47,
  button: 1.286,
  double: 2,
  large: 1.75,
  medium: 1.4,
  bodyText: 1.588,
  tight: 1.1818,
  none: 0,
};
